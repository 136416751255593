import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['icon', 'text']

  enableButton() {
    this.element.removeAttribute('disabled')
    this.iconTarget.classList.add('hidden')
    this.textTarget.textContent = this.initialText
  }

  disableButton() {
    this.element.setAttribute('disabled', 'disabled')
    this.iconTarget.classList.remove('hidden')
    this.textTarget.textContent = this.data.get('loadingText')
  }

  connect() {
    console.log('Button controller loaded')
    this.initialText = this.textTarget.textContent

    if (this.data.get('onclick') != 'false') {
      this.element.addEventListener('click', event => {
        this.disableButton()
      })
    }

    this.element.addEventListener('button.disable', event => {
      this.disableButton()
    })

    this.element.addEventListener('button.enable', event => {
      this.enableButton()
    })
  }

}
