import { Controller } from 'stimulus'
import Glide, { Controls } from '@glidejs/glide/dist/glide.modular.esm'

export default class extends Controller {

  static targets = ['glide']

  connect() {
    this.glide = new Glide(this.glideTarget, {
      type: 'slider',
      startAt: 0,
      perView: 3,
      bound: true,
      gap: 50
    })
    // this.glide.mount()
    this.glide.mount({ Controls })
  }

  goPrevious() {
    this.glide.go('<')
  }

  goNext() {
    this.glide.go('>')
  }
}