import { Controller } from 'stimulus'
import { debounce, isPrintableCharacter } from '../src/utils'

export default class extends Controller {
  static targets = ['hiddenField', 'input', 'submit', 'popup', 'popupBody']

  connect() {
    this.selectedItemIndex = null;
    this.url = this.data.get('url')
    this.search = debounce(() => this.load(this.inputTarget.value), 100)
    this.maxIndex = 0
    this.closeHandler = null
    // DEBUG
    // this.inputTarget.focus()
    // this.load('impacts')
  }

  pressKey(event) {
    if (event.keyCode === 13) {
      event.stopPropagation() & event.preventDefault()
      this.selectOnEnter()
    } else if (event.keyCode === 38) {
      this.highlightPreviousSelectedItem()            
    } else if (event.keyCode === 40) {
      this.highlightNextSelectedItem()      
    } else if (isPrintableCharacter(event.keyCode) || event.keyCode === 8)
      this.search()
  }

  load(terms) {
    if (terms.length <= 2) {
      this.close()
      return
    }

    fetch(`${this.url}?q=${terms}`)
    .then(response => response.text())
    .then(html => {
      this.selectedItemIndex = null
      this.popupBodyTarget.innerHTML = html
      this.popupTarget.classList.remove('hidden')
      this.maxIndex = parseInt(this.popupTarget.querySelector('div[data-index]:last-child')?.dataset?.index) || 0
      
      if (this.maxIndex === 0) this.reset()
    })
  }

  selectOnEnter() {
    if (this.selectedItemIndex !== null) {
      const selectedItem = this.popupTarget.querySelector(`div[data-index='${this.selectedItemIndex}']`)
      this.select(selectedItem.dataset)
    }
  }

  selectOnClick(event) {
    if (this.closeHandler) clearTimeout(this.closeHandler)
    this.select(event.currentTarget.dataset)
  }

  select({ label, id }) {
    this.inputTarget.value = label
    this.hiddenFieldTarget.value = id
    this.submitTarget.classList.remove('hidden') 
    this.popupTarget.classList.add('hidden')
    this.inputTarget.focus()
  }

  reset() {
    this.selectedItemIndex = null
    this.hiddenFieldTarget.value = null
    this.submitTarget.classList.add('hidden')
  }

  close(event) {
    // wait for a click on an element
    this.closeHandler = setTimeout(() => {    
      this.closeHandler = null
      this.reset()
      this.popupTarget.classList.add('hidden')
    }, 300)    
  }

  highlightHoveredItem(event) {
    const nextIndex = parseInt(event.currentTarget.dataset.index)
    this.highlightItem(nextIndex)
  }

  highlightNextSelectedItem() {
    let nextIndex = this.selectedItemIndex === null ? 0 : this.selectedItemIndex + 1
    if (nextIndex > this.maxIndex) nextIndex = 0
    this.highlightItem(nextIndex)
  }  

  highlightPreviousSelectedItem() {
    let nextIndex = (this.selectedItemIndex === null ? 0 : this.selectedItemIndex) - 1
    if (nextIndex === -1) nextIndex = this.maxIndex
    this.highlightItem(nextIndex)
  }

  highlightItem(nextIndex) {
    if (this.selectedItemIndex !== null) {
      const currentSelectedItem = this.popupTarget.querySelector(`div[data-index='${this.selectedItemIndex}']`)
      currentSelectedItem.classList.remove('bg-red-100')
    }

    const nextSelectedItem = this.popupTarget.querySelector(`div[data-index='${nextIndex}']`)

    if (nextSelectedItem) {
      nextSelectedItem.focus()
      nextSelectedItem.classList.add('bg-red-100')
      this.selectedItemIndex = nextIndex
    }
  }
}
