import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['select', 'pane']

  connect() {
    this.refresh()
  }

  refresh() {
    const value = this.selectTarget.value
    this.paneTargets.forEach(pane => {
      if (pane.dataset.paneId === value)
        pane.classList.remove('hidden')
      else
        pane.classList.add('hidden')
    })
  }

}