import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { url: String, priceId: String }

  static targets = ['error', 'submitButton']

  connect() {
    console.log('Stripe checkout session controller enabled!')
    this.csrfToken = document.querySelector("[name='csrf-token']").content
    this.stripePublishableKey = document.querySelector("[name='stripe-publishable-key']").content

    this.stripe = Stripe(this.stripePublishableKey, { locale: 'fr' })
  }

  submit(event) {
    event.stopPropagation() & event.preventDefault()
    this.submitButtonTarget.dispatchEvent(new Event('button.disable'))
    this.createSession()    
  }

  // https://stripe.com/docs/payments/accept-a-payment?integration=elements
  createSession() {    
    return fetch(this.urlValue, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': this.csrfToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        price_id: this.priceIdValue,
      }),
    })
    .then(response => response.json())
    .then(session => {
      return this.stripe.redirectToCheckout({ sessionId: session.id })
    })
    .then(result => {
      if (result.error) {
        throw result
      }
      return result
    })
    .catch(error => this.displayError({ error }))
  }

  displayError(event) {
    console.log(event.error)
    this.submitButtonTarget.dispatchEvent(new Event('button.enable'))
    if (event.error) {
      this.errorTarget.textContent = event.error.message
      this.errorTarget.classList.remove('hidden')
    } else {
      this.errorTarget.textContent = ''
      this.errorTarget.classList.add('hidden')
    }
  }
}