import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['filename', 'fileField', 'removeFileField', 'showButton', 'deleteButton', 'cancelButton']

  connect() {
    this.defaultFilename = this.filenameTarget.dataset.defaultFilename;
    this.originalFilename = this.filenameTarget.innerHTML;
    this.isDeleted = false;
    this.isDeletable = this.filenameTarget.dataset.attached === 'true';
    if (this.isDeletable) {
      this._showButton('show');
      this._showButton('delete');
    }
  }

  select(event) {    
    const newFile = event.target.files[0];
    this._setNewFile(newFile);    
  }

  delete() {
    this._hideButton('show');
    this._hideButton('delete');
    this._showButton('cancel');
    this._setFilename('');
    this.isDeleted = true;
    this.removeFileFieldTarget.value = '1';  
  }

  cancel() {
    this._hideButton('cancel');
    this._setFilename(this.originalFilename);
    if (this.isDeleted) {
      this.isDeleted = false;
      this.removeFileFieldTarget.value = '';
      this._showButton('show');
      this._showButton('delete');
    } else {
      this.fileFieldTarget.value = null;
    }    
  }  

  _setNewFile(newFile) {
    this._hideButton('show');
    this._showButton('cancel');
    this._setFilename(newFile.name);
    this.isDeleted = false;
    this.removeFileFieldTarget.value = '';
  }

  _setFilename(filename) {
    this.filenameTarget.innerHTML = filename || this.defaultFilename;
  }

  _showButton(type) {
    const el = this.targets.find(`${type}Button`);
    if (!el) return;
    el.classList.remove('hidden');
  }

  _hideButton(type) {
    const el = this.targets.find(`${type}Button`);
    if (!el) return;
    el.classList.add('hidden');
  }
}