import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['image', 'fileField', 'removeFileField', 'deleteButton', 'cancelButton']

  connect() {
    this.defaultImageUrl = this.imageTarget.dataset.defaultUrl;
    this.originalImageUrl = this.imageTarget.src;
    this.isDeleted = false;
    this.isDeletable = this.imageTarget.dataset.attached === 'true';
    if (this.isDeletable)
      this._showButton('delete');
  }

  select(event) {    
    const newImage = event.target.files[0];
    var reader = new FileReader();
    reader.onload = binaryImage => this._setNewImage(binaryImage);
    reader.readAsDataURL(newImage);
  }

  delete() {
    this._hideButton('delete');
    this._showButton('cancel');
    this._setBackground('');
    this.isDeleted = true;
    this.removeFileFieldTarget.value = '1';  
  }

  cancel() {
    this._hideButton('cancel');
    this._setBackground(this.originalImageUrl);
    if (this.isDeleted) {
      this.isDeleted = false;
      this.removeFileFieldTarget.value = '';
      this._showButton('delete');
    } else {
      this.fileFieldTarget.value = null;
    }    
  }  

  _setNewImage(binaryImage) {
    this._showButton('cancel');
    this._setBackground(binaryImage.currentTarget.result);
    this.isDeleted = false;
    this.removeFileFieldTarget.value = '';
  }

  _setBackground(url) {
    this.imageTarget.src = url || this.defaultImageUrl;
  }

  _showButton(type) {
    this.targets.find(`${type}Button`).classList.remove('hidden');
  }

  _hideButton(type) {
    this.targets.find(`${type}Button`).classList.add('hidden');
  }
}