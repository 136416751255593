import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { url: String, redirectUrl: String, priceId: String }

  static targets = ['error', 'card', 'submitButton']

  connect() {
    console.log('Stripe subscription controller enabled!')
    this.csrfToken = document.querySelector("[name='csrf-token']").content
    this.stripePublishableKey = document.querySelector("[name='stripe-publishable-key']").content
    
    this.stripe = Stripe(this.stripePublishableKey, { locale: 'fr' })
    this.elements = this.stripe.elements()
    this.card = this.elements.create('card', { style: {} });
    this.card.mount(this.cardTarget);

    this.card.on('change', event => this.displayError(event))

    this.element.addEventListener('submit', event => {
      event.stopPropagation() & event.preventDefault()
      this.submitButtonTarget.dispatchEvent(new Event('button.disable'))
      this.createPaymentMethod()
      .then(paymentMethodId => this.createSubscription(paymentMethodId))
      .then(({ subscription }) => {
        console.log('subscription created!', subscription)
        window.location.href = this.redirectUrlValue
      })
      .catch(error => this.displayError(error))
    })
  }  

  createSubscription(paymentMethodId) {    
    return fetch(this.urlValue, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': this.csrfToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        payment_method_id: paymentMethodId,
        price_id: this.priceIdValue,
      }),
    })
    .then(response => response.json())
    .then(result => {
      if (result.error) {
        throw result
      }
      return result
    })
    .then(result => {
      return {
        // Use the Stripe 'object' property on the
        // returned result to understand what object is returned.
        subscription: result,
        paymentMethodId: paymentMethodId,
        priceId: this.priceIdValue
      }
    })
  }

  createPaymentMethod() {
    return this.stripe.createPaymentMethod({
      type: 'card',
      card: this.card,
    }).then(result => {
      if (result.error) {
        throw result
      } 
      return result.paymentMethod.id
    })
    
  }

  displayError(event) {
    this.submitButtonTarget.dispatchEvent(new Event('button.enable'))
    if (event.error) {
      this.errorTarget.textContent = event.error.message
      this.errorTarget.classList.remove('hidden')
    } else {
      this.errorTarget.textContent = ''
      this.errorTarget.classList.add('hidden')
    }
  }
}