import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['tab', 'tabPane']
  static values = { firstIndex: Number, activeClass: String, inactiveClass: String }

  connect() {
    this.currentIndex = this.firstIndexValue || 0
    this.showTab(this.currentIndex)
  }  

  select(event) {
    event.stopPropagation() & event.preventDefault()
    const tab = event.target.closest('[data-index]')
    const index = parseInt(tab.dataset.index)
    this.showTab(index)
  }

  showTab(index) {
    const activeClassList = this.activeClassValue.split(' ')
    const inactiveClassList = this.inactiveClassValue.split(' ')

    // highlight the current tab
    for (var i = 0; i < this.tabTargets.length; i++) {
      this.tabTargets[i].classList.remove(...activeClassList)
      this.tabTargets[i].classList.add(...inactiveClassList)
    }

    this.tabTargets[index].classList.add(...activeClassList)
    this.tabTargets[index].classList.remove(...inactiveClassList)

    // display the current pane (and hide the previous one)
    this.tabPaneTargets[this.currentIndex].classList.add('hidden')
    this.tabPaneTargets[index].classList.remove('hidden')

    this.currentIndex = index
  }
}