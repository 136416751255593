import { Controller } from 'stimulus'
import { debounce, isPrintableCharacter } from '../src/utils'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

delete L.Icon.Default.prototype._getIconUrl
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('../images/leaflet/marker-icon-2x-black.png'),
  iconUrl: require('../images/leaflet/marker-icon-black.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

export default class extends Controller {
  static values = { singleMode: Boolean }
  static targets = ['map', 'point']

  markers = []
  blueIcon = L.icon({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
  })
  blackIcon = L.icon({
    iconRetinaUrl: require('../images/leaflet/marker-icon-2x-black.png'),
    iconUrl: require('../images/leaflet/marker-icon-black.png'),
  })

  connect() {
    this.map = this.buildMap()
    this.markers = []
    this.addPoints()
  }

  buildMap() {
    var map = L.map(this.mapTarget).setView(this.centerCoordinates(), this.zoomLevel())
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map)
    return map
  }

  addPoints() {
    this.pointTargets.forEach(point => {
      const lat = point.dataset.lat
      const lng = point.dataset.lng
      const marker = L.marker([lat, lng], { id: point.dataset.index })
      marker.addTo(this.map)
      this.markers[point.dataset.index] = marker
    })
  }

  centerCoordinates() {
    if (this.singleModeValue)
      return [this.pointTargets[0].dataset.lat, this.pointTargets[0].dataset.lng]       
    else
      return [46.5392, 2.4302]
  }

  zoomLevel() {
    return this.singleModeValue ? 16 : 6
  }

  showPartner(event) {
    if (event.target.dataset.partnerIndex) {
      this.markers[event.target.dataset.partnerIndex].setIcon(this.blueIcon)
    }
  }

  hidePartner(event) {
    const parent = event.relatedTarget.parentNode
    const inParentNode = parent.dataset.partnerIndex || parent.parentNode.dataset.partnerIndex
    if (event.target.dataset.partnerIndex && !inParentNode) {
      this.markers[event.target.dataset.partnerIndex].setIcon(this.blackIcon)
    }
  }
}