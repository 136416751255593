import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['button', 'popup']

  toggle(event) {
    event.preventDefault() & event.stopPropagation()
    if (this.popupTarget.classList.contains('hidden'))
      this.popupTarget.classList.remove('hidden')
    else
      this.popupTarget.classList.add('hidden')
  }

  hide(event) {
    if (this.element.contains(event.target) && event.type !== 'blur') {
      return
    }
    this.popupTarget.classList.add('hidden')
  }
}
